import React, {Fragment}		from "react"
import {GridManager} 			from "./grid_manager.js";
import {PEMTViewModel}			from "../viewmodel/pemt_view_model.js"
import {Axis} 					from "../datamodel/axis.js"
import {normalizeSchema}		from "../tree/tree.js"


// NormalYear = ThaiYear - 543



function find_years( years, node )
{
	if (node.data)
	{
		for( var i in node.data )
		{
			if (!years.includes( i ))
				years.push(i)
		}
	}

	if (node.nodes)
		node.nodes.map( (x) => find_years( years, x) )

	return years.sort()
}



export class PEMTDataModel
{
	walk_tree( i18, node, province )
	{
		if (node.data)
		{
			for( let year in node.data )
			{
				const value 	= node.data[year]
				const rounded 	= Math.round( value * 100.0 ) / 100.0
				this.data_store.set( {province: province, year: year, parameter: node.key}, rounded )
			}
		}

		if (node.nodes)
			node.nodes.forEach( (x) => this.walk_tree( i18, x, province ) )
	}

	translate_tree( i18,node )
	{
		let key = node.nodes ? `${node.id}` : node.uc_key
		key = key.replace( "1.1.1", "SECTION_A")
		key = key.replace( "1.1.2", "SECTION_B")
		key = key.replace( "1.1.3", "SECTION_C")

		let 	i18_key = `INPUT:${key}`
		const 	translate = i18(false, i18_key)

		if (i18_key == 'INPUT:1.1')
			i18_key = "--BLANK--"

		if (translate)
			node.i18_key = i18_key

		if (node.nodes)
			node.nodes.forEach((x) => this.translate_tree(i18, x))
	}


	add_data_to_store( i18, province )
	{
		this.walk_tree( i18, this.schema, province )
	}

	setup_data( i18, tree, provinces )
	{
		this.schema 				= normalizeSchema( tree )

		this.translate_tree( i18, this.schema )

		this.years 					= find_years( [], tree )

		const axis_province 		= new Axis( "province",  "Province", 	"alpha", 	{list: provinces} )
		const axis_year 			= new Axis( "year", 	 "Year", 		"numeric",  {list: this.years}, (x) => <Fragment>{x}<br/><i>({x-543})</i></Fragment> )
		const axis_parameter 		= new Axis( "parameter", "Parameter", 	"alpha",  	{tree: this.schema} )


		this.data_store 			= new GridManager( [axis_year, axis_parameter, axis_province] )
		this.view_model 			= new PEMTViewModel(this.data_store )

		this.view_model.setSelectedNodeCallback( (node) =>
		{
			if (node.nodes)
        		this.view_model.chart_node = node.nodes
      		else
        		this.view_model.chart_node = [node]
	  		this.view_model.chart_type = node.chart_type
		})

		// Select the 0 element of the axes
		this.view_model.axisKeyValueListX()

		this.view_model.setSelectedValue( "year", 		this.view_model.axisKeyValueListX()[0].node )
		this.view_model.setSelectedValue( "parameter", 	this.view_model.axisKeyValueListY()[0].node )
		this.view_model.setSelectedValue( "province", 	this.view_model.axisKeyValueListZ()[0].node )
	}


	constructor(  {tree, years, province, i18} )
	{
		this.setup_data( i18, tree, [province] )
		this.add_data_to_store( i18, province )
	}
}

