import React, { Component, Fragment }                                  from 'react';
import {DataNode}                                                      from './DataNode';
import {findNode, addNewNode, normalizeSchema, mapTree,
        visitNodesWithParent, cloneNode}                               from './tree/tree.js';
import classnames                                                      from 'classnames';

import * as utils                                                      from './utils/utils';



export class TreeNode extends Component
{

  state = {
  }

  selectFactory     = (column)  => { return (event) => this.props.editcontroller.selectNode(   this.props.node, column ) }
  selectChartFactory= (column)  => { return (event) => this.props.editcontroller.chartNode(    this.props.node, column ) }
  editStartFactory  = (column)  => { return (event) => this.props.editcontroller.startEditing( this.props.node, column ) }
  openClose         = (event)   => {                   this.props.editcontroller.openClose(    this.props.node ) }


  tabDown = false


  componentDidUpdate()
  {
    this.editcontroller  = this.props.editcontroller; // Check if this is necessary
  }


  indent( level )
  {
    let rv = []
    for( let i = 0; i < level; i++ )
      rv.push( <div onClick={this.openClose}
                    key={`indent${this.props.node.id}-${i}`}
                    className='indent'></div> )
    return rv
  }



  cellClass( name, type, col )
  {
    const {editcontroller, node}              = this.props
    const {editing, focusNode, column}        = editcontroller
    const rowFocused                          = focusNode === node.key
    const colFocused                          = column    === col

    return classnames( "cell", type, name, {focused: rowFocused && colFocused} )
  }



  chartType()
  {
    const {view_model, node} = this.props
    const x_axis = view_model.getAxisX()


    if (node.level < 2 || !node.data)
      return null;

    let chart_type =  node.chart_type

    if (chart_type == 'stacked_bar')
        chart_type  = "bar"
    else if (chart_type == 'stacked_area')
        chart_type  = 'area'
    else if (x_axis.kind == "numeric")
        chart_type  = "line"
    else
        chart_type  = "bar"

    return chart_type
  }



  render_extra_columns = ( extras, node ) =>
  {
    return extras.map( (e,idx) =>
      {
        const   classes   = classnames( "value", e)
        return    <div key={`extra-${idx}`} className    = {classes}>
                    {node.level == 0 ? '' : (node[e] || '')}
                  </div>
      })
  }



  render()
  {
    const {node, children}                = this.props
    const {i18}                           = this.props
    const {editcontroller, view_model}    = this.props

    const opened                          = view_model.isOpen( node )
    const {name, definition,unit}         = node
    const defString                       = '' //definition  ? ` (${definition})` : ''
    const uniti18                         = i18 ? i18(unit) : unit
    const unitString                      = uniti18  ? `${uniti18}`     : ''

    const is_leaf_node  = !(node.level > 0 && node.nodes && node.nodes.length > 0)
    const indicator     = !is_leaf_node ? (opened ? '▾': '▸') : ''
    const chart_icon    = this.chartType()


    let displayname     = node.name
    if (i18 && node.i18_key)
      displayname = i18( node.i18_key )
    if (i18 && !node.i18_key)
      displayname = node.name

    const nameClasses   = classnames( "value", "name")
    const leafClass     = is_leaf_node ? 'leaf' : 'branch'

    const z_axis          = view_model.getSelectedValueOnAxis("z")
    const z_value         = z_axis == null ? null : z_axis.key
    const has_empty       = is_leaf_node  && view_model.rowHasEmptyValue( node.key, z_value )



    return  <Fragment>
              <div className={`row level-${node.level} ${leafClass}`}>
                  <div    className     = {this.cellClass( "name", "row-head", 0) }
                          onDoubleClick = {this.openClose}>
                    <div className='cell-contents'>
                      {
                        (has_empty && this.props.warnings_empty !== false) &&
                          <i className='warning fa fa-exclamation-circle'/>
                      }
                      {
                        (!has_empty  && this.props.warnings_empty !== false) &&
                          <i className='warning section'>&nbsp;</i>
                      }
                      {this.indent( node.level )}
                      <div  className   = 'indicator'
                            onClick     = {this.openClose}>
                          {indicator}
                      </div>
                      <div  className   = {nameClasses}
                          onClick       = {this.selectFactory( 0 )}>
                          {node.level == 0 ? <b></b> : `${displayname}` }
                          &nbsp;
                          <i>{defString}</i>
                      </div>
                      {
                          view_model.extra_columns && this.render_extra_columns( view_model.extra_columns, node )
                      }
                      {
                        this.props.display_units &&
                        <div className='value unit'>
                            {unitString}
                        </div>
                      }
                      &nbsp; &nbsp;
                      {
                        (chart_icon) &&
                        <i onClick={this.selectChartFactory( 0 )}
                           className={classnames( "chart-icon", "fa", `fa-chart-${chart_icon}`)}/>
                      }
                    </div>
                  </div>
              </div>
              { opened &&
                <Fragment>
                  {children}
                </Fragment>
              }
            </Fragment>
  }
}
