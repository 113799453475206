import React, { Component, Fragment } 	from "react";
import classnames       				from 'classnames';
import JSONPretty 						from 'react-json-pretty'
import {CopyToClipboard} 				from 'react-copy-to-clipboard'
import backend_config					from "../config/runconfig"
import {visitNodes}         			from '../tree/tree'
import axios 							from 'axios'
import {PEMTDataModel}   				from "../datamodel/pemt_data_model"
import {TreeEditor}						from "../TreeEditor"
import {QDataModel}   					from "./qdata-viewmodel"
import {DependencyGraph}				from "./DependencyGraph"
import UnitSelector						from '../units/UnitSelector'
import {AppController} 					from '../app/AppController'
import {ScenarioSelector} 				from '../scenario/ScenarioSelector'


const API_KEY 		= 'ExgWFH9XHHyXIHyKEHIH'


export class CDataExplorer extends Component
{
	static contextType = AppController;
	constructor( props )
	{
		super( props )
		this.state 	= {
			years				: null,
			energy				: 'MWh',
			area 				: 'm²',
			currency			: 'EUR',
			scenario			: this.get_scenario(),
		}
		if (this.props.year)
			this.state.years = [this.props.year]
		else
        	this.state.years = ['2560']

        this.all_years 		 = []
        for( let year = 2551; year < 2561; year++ )
        	this.all_years.push( `${year}` )
	}



	fetch_expression()
	{
		if (this.inflight)
			return

		const component 				= this
		const province  				= this.props.province
		const {energy,area,currency}	= this.state
		const scenario					= this.get_scenario() || '1'


				const keys  	= [

							'SD_ProjGGpp',
							'SD_ProjGPop',


							'SD_ProjFPop',
							'SD_ProjFGpp',
							'SD_ProjFEDres',
							'SD_ProjFEDagr',
							'SD_ProjFEDind',
							'SD_ProjFEDcom',
							'SD_ProjFEDoth',
							'SD_ProjFHDres',
							'SD_ProjFHDagr',
							'SD_ProjFHDind',
							'SD_ProjFHDcom',
							'SD_ProjFHDoth',
							'SD_ProjFMob',
							'SD_ProjFN',
							'SD_PopNo',
							'SD_Gpp',

							'SD_FinalEDres',
							'SD_FinalEDagr',
							'SD_FinalEDind',
							'SD_FinalEDcom',
							'SD_FinalEDtra',
							'SD_FinalEDoth',
							'SD_FinalHDres',
							'SD_FinalHDagr',
							'SD_FinalHDind',
							'SD_FinalHDcom',
							'SD_FinalHDtra',
							'SD_FinalHDoth',

							'QD_DEMDEVEPLOTSUPP1',
							'QD_FINALHTOTHO',
							'QD_FINALMTOTXI',
							'QD_FINALNTOTXI',
							'SD_FINALE',
							'SD_FINALH',
							'SD_FINALM',
							'SD_FINALN',

						].map( x => x.toUpperCase() )


		const key 		= keys.join(',')
		const input_keys = this.props.keys ? this.props.keys.split(",")
															.map( x => `${x}`.toUpperCase().trim())
															.filter( x => x.startsWith("SD_")).join(",") : null
		const used_keys 	= input_keys || key


		const units 	= `convert_units=true&energy=${energy}&currency=${currency}&area=${area}`
		const year_key	= this.context ? this.context.year_key : 'NONE'
		const url = `${backend_config.server}/sdata/query?year=2560&convert_units=true&energy=${energy}&currency=${currency}&area=${area}&key=${used_keys}&province=${province}&scenario=${scenario}&api_key=${API_KEY}&year_key=${year_key}`

		if (url === this.url_displayed)
			return
		this.inflight = true
		console.log(url)
		axios.get(url)
			.then((incoming) => {
				this.url_displayed = url
				this.inflight = false

				const { data } = incoming
				if (data.status === 'error') {
					console.log("Error Loading expression")
					console.log(data.error)
					component.setState({ error: true, expressions: null })
				}
				else
				{
					const new_state = {energy,currency,area}
					new_state.error 		= 	false
					new_state.errorMsg 		= 	null
					new_state.expressions 	=  	data.result.value
					new_state.units 		= 	data.result.units
					new_state.is_head_data 	= 	data.result.is_head_data
					this.qdata_model		= null  // To force a recalculation
					component.setState( new_state )
				}
		})
	}

	setUnits 		= (units) 	=> 		this.setState( units )

	close_graph 	= () 		 => 	{this.focus_variable( null, null )}


	focus_variable(year, varname)
	{
		const currentpath = this.props.history.location.pathname


      	const {keys}  = this.props
      	const keydisp = keys ? `&keys=${keys}` : ''

		const params = []
		if (year == null || varname == null)
			this.props.history.push(`${currentpath}?${keydisp}`)
		else
			this.props.history.push(`${currentpath}?year=${year}&varname=${varname}${keydisp}`)
	}


	componentDidMount() {
		this.fetch_expression()
	}

	componentDidUpdate() {
		this.fetch_expression()
	}


	setup_qdata_model()
	{
		console.log( "SETUP OF THE MODEL")
		if (!this.state.expressions)
			return null


		const parameters = {	tree: 		this.state.expressions,
								years: 		this.all_years,
								province: 	this.props.province
							}
		const qdata_model 	= new QDataModel(parameters)
		qdata_model.view_model.setSelectedNodeCallback( (node, column, year) =>
		{
			this.focus_variable( year.key, node.key )
		})
		return qdata_model
	}
	get_scenario ()
	{
		return this.props.match.params.scenario || 'P001'
	}

	set_scenario 	= ({scenario})	=>
	{
			const province 	= this.props.province
			const location 	= this.props.location
			this.props.history.replace(`/cdata/${province}/${scenario}${location.search}`)
	}

	render()
	{
		if (!this.qdata_model)
			this.qdata_model = this.setup_qdata_model()

		if (this.qdata_model)
			this.qdata_model.focusYear( this.state.focused_year )


		const focused_expression = this.props.varname && this.props.year && this.state.expressions
		const chart_on = focused_expression ? 'chart-on' : 'chart-off'


		const p_key 		= this.props.province.toUpperCase()
		const province_data = this.context.provinces ? this.context.provinces[p_key] : null

		return <Fragment>
					<div className='sankey-main'>
						<div className='sankey-menu'>
							<div className='section'>
							{
									<ScenarioSelector province_data={province_data} scenario={this.get_scenario()} callback={this.set_scenario} />
							}
							</div>
							{ this.qdata_model &&
								<UnitSelector units={this.state.units} callback={this.setUnits} />
							}
							{
								this.qdata_model && this.state && this.state.is_head_data == false &&
								<div className='warning section'>
									<i className='fa fa-exclamation-circle'/>
									Diagram is not based on latest data
								</div>
							}
							{
								this.state.error &&
								<div className='warning section'>Data for scenario not available</div>
							}
						</div>
						<div className='sankey-container with-expression'>
							<div className={classnames("bottom", "grid-on",
														"sidebar-on", chart_on)}>
								{
									this.qdata_model &&
									<TreeEditor narrow_header={true}
												view_model={this.qdata_model.view_model}
												commit={this.commit} />
								}
							</div>
						</div>
					</div>
					{
						focused_expression &&
						<div className='depgraph'>
							<DependencyGraph expressions={this.state.expressions}
								kind='sd'
								history={this.props.history}
								year={this.props.year}
								varname={this.props.varname}
								scenario={this.get_scenario()}
								selected_node={this.props.selected_node}
								province={this.props.province}
								keys={this.props.keys}
								close_graph={this.close_graph} />
						</div>
					}

				</Fragment>
	}


}


export default CDataExplorer;