
// This makes sure JSON with simple string for tags get into a standardized format, and adds a level
// to the tree
export function normalizeSchema( root )
{
	return normalizeAndMap( root, null, 0, 1, null )
}

export function cloneNode( node )
{
	return normalizeSchema( Object.assign( {}, root ), null, 0, 1, null )
}

export function mapTree( root, fn )
{
	return normalizeAndMap( root, fn, 0, 1, null )
}

export function flatMapTree( root, fn )
{
	const result = []
	mapTree( root, (x) => {
		result.push( fn( x ))
	})
	return result
}

// This generates a random key
function createKey()
{
	const stri = 'abcdefghijklmnopqrstuvwxyz1234567890'
	const key = []
	for( let i = 0; i < 17; i++ )
	{
		if (i == 5 || i == 11)
			key.push("-")
		else
		{
			const keyVal = (Math.round(Math.random() * 10000) * Date.now()) % stri.length
			key.push( stri[keyVal] )
		}
	}
	return key.join('')
}


// This function visits the tag tree depth first and returns a new tree
// with each node having the function applied to it.
// the function has to return a new node, and the tag field of the new
// node can be a new list (or null) or a copy of the old list. If it contains
// a copy of the old list this map will copy the list for it. In this
// way a function can create a new tree topology be cutting elements from
// the tag list. Otherwise it can ignore the tag list and the root tag list will be
// copied and mapped.
// Example: a function (node) => {name: "yyy " + node.name} will still copy the whole tree
// The function will auto-index the tree and normalize it which means the function cannot create
// an element called id or level in the node
function normalizeAndMap( entry, fn, level, index, parent_id )
{
	if (entry === null || entry === undefined)
		return null;

	if (typeof entry == "string")
		entry = {name: entry};

	entry.level 		= level;
	entry.id 			= parent_id  ? `${parent_id}.${index}` : `${index}`;
	if (!entry.key)
		entry.key = createKey()
	if (entry.level == 0 || entry.name == '_ROOT_')
		entry.key == '_ROOT_'
	entry.index 		= index;
	const new_node 	= fn ? fn( entry ) : entry
	const src_list 	= new_node.nodes || entry.nodes
	if (src_list)
	{
		new_node.nodes = []
		var offset    = 0;
		for( let e of src_list )
		{
			offset++;
			new_node.nodes.push( normalizeAndMap( e, fn, level + 1, offset, entry.id ) );
		}
	}

	entry.has_nodes = entry.nodes && entry.nodes.length > 0
	return new_node;
}


// this function will return a node as well as the parent node in the tree where the node has a specific key or id
// Since keys and ids cannot overlap, even in principle, it will search for either
export function findNode( parent, search )
{
  if (!search || !parent)
  	return null
  if (parent.nodes)
  {
    for( const index in parent.nodes )
    {
      const tag 		= parent.nodes[index]
      const i_index  	= parseInt( index )		// Am I supposed to understand why the hell index is a string??!!
      if (tag.id === search || tag.key === search)
        return {parent, tag, index: i_index}
      const deeper = findNode( tag, search )
      if (deeper)
      	return deeper
    }
  }
  return null;
}



export function addNewNode( parent )
{
    if (!parent.nodes)
    	parent.nodes =  []
    const index 	=  parent.nodes.length + 1
    const newID   	=  `${parent.id}.${index}`
    var   new_node 	=  {
    	name: 	null,
    	key: 	createKey(),
    	id: 	newID,
    	index: 	index
    }

	parent.nodes.push( new_node )
    return new_node
}



// This function visits the tag tree depth first
export function visitNodes( root,  visitroot = true, fn )
{
	if (!root || !fn)
		return;
	if (visitroot == true || root.key != '_ROOT_')
		fn( root )
	if (root.nodes)
	{
		for( const tag of root.nodes )
			visitNodes( tag, visitroot, fn )
	}
}


// This function visits the tag tree depth first
export function visitNodesWithParent( root, fn )
{
	if (!root || !fn)
		return;
	if (root.nodes)
	{
		for( const tag of root.nodes )
		{
			fn( root, tag )
			visitNodesWithParent( tag, fn )
		}
	}
}




